<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Properties</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-easylets-properties' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Properties
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="properties"
        class="mt-4"
        no-data-text="No Properties found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Property</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.property.full_address }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import PropertyDialog from "./components/PropertyDialog.vue";

export default {
  components: {
    //PropertyDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Address", value: "full_address" },

        { text: "Monthly Rent", value: "monthly_rent" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        property: {},
      },
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["easylets/propertiesStore/archived"];

      if (this.searchTerm !== "") {
        properties = properties.filter((c) => {
          const fullAddress = c.full_address.toLowerCase();
          const propertyMenager =
            c.property_manager !== null ? c.property_manager.toLowerCase() : "";

          const searchTerm = this.searchTerm.toLowerCase();

          return (
            fullAddress.includes(searchTerm) ||
            propertyMenager.includes(searchTerm)
          );
        });
      }

      return properties;
    },
  },

  methods: {
    openRestore(property) {
      this.restoreDialog.property = property;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.property = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("easylets/propertiesStore/restoreProperty", {
          appId,
          propertyId: this.restoreDialog.property.property_id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
